import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import {
	getFanTransactionsService,
	getCurrenciesService,
	getFanTransactionStatusService,
	getFanTransactionsTotalService,
} from 'services/FanPurchaseTransactionService'
import { createSelectOptions } from 'utils/form.util'

const useFanPurchaseTransactionsTable = () => {
	const initialFilters = {
		search: '',
		status: '',
		currency: '',
		from: null,
		to: null,
		limit: 5,
		offset: 0,
	}

	const [reload, setReaload] = useState(false)
	const [filters, setFilters] = useState(initialFilters)

	const [totals, setTotals] = useState([])
	const [transactions, setTransactions] = useState({
		rows: [],
		count: 0,
	})
	const [statuses, setStatuses] = useState([])
	const [currencies, setCurrencies] = useState([])

	const afterFetchTransactions = (data, error) => {
		if (!error) {
			setTransactions(data)
		}
	}

	const afterFetchTotals = (data, error) => {
		if (!error) {
			setTotals(data)
		}
	}

	const afterFetchStatuses = (data, error) => {
		if (!error) {
			setStatuses(data)
		}
	}

	const afterFetchCurrencies = (data, error) => {
		if (!error) {
			const options = createSelectOptions(data)
			setCurrencies(options)
		}
	}

	const [{ loading: loadingTotals }, fetchTotals] = useAxios(
		getFanTransactionsTotalService(),
		afterFetchTotals
	)

	const [{ loading: loadingTransactions }, fetchTransactions] = useAxios(

		getFanTransactionsService(filters),
		afterFetchTransactions
	)

	const [{ loading: loadingStatuses }, fetchStatuses] = useAxios(
		getFanTransactionStatusService(),
		afterFetchStatuses
	)

	const [{ loading: loadingCurrencies }, fetchCurrencies] = useAxios(
		getCurrenciesService(),
		afterFetchCurrencies
	)

	const loading = loadingTotals || loadingTransactions || loadingStatuses || loadingCurrencies

	useEffect(() => {
		fetchTransactions()
	}, [filters, reload])

	useEffect(() => {
		fetchStatuses()
		fetchCurrencies()
		fetchTotals()
	}, [])

	const handleClearFilter = () => {
		setFilters(initialFilters);
	}

	const handleChangeLimit = (limit) => {
		setFilters((prv) => {
			return { ...prv, limit: limit }
		})
	}

	const setOffset = (offset) => {
		setFilters(prv => {
			if (prv.offset === offset) {
				return prv
			}
			return { ...prv, offset: offset }
		})


	}

	const handleChangeSearch = (search) => {
		setFilters(prv => {
			return {
				...prv,
				search: search,
				from: null,
				to: null,
				currency: '',
				status: '',
				offset: 0
			}
		})

	}

	const handleChangeStatus = (status) => {
		setFilters((prv) => {
			return { ...prv, status: status }
		})
	}


	const handleChangeCurrency = (id, label) => {
		setFilters((prv) => {
			return { ...prv, currency: label }
		})
	}

	const handleChangeFromDateRange = (data) => {
		setFilters(prv => {
			return { ...prv, from: data.from }
		})
	}

	const handleChangeToDateRange = (data) => {
		setFilters(prv => {
			return { ...prv, to: data.to }
		})
	}

	return {
		loading,
		totals,
		data: transactions,
		filters: filters,
		statuses,
		currencies,
		handleClearFilter,
		handleChangeLimit,
		setOffset,
		handleChangeSearch,
		handleChangeStatus,
		handleChangeCurrency,
		handleChangeFromDateRange,
		handleChangeToDateRange,
	}
}

export default useFanPurchaseTransactionsTable
