export const getMarketingUsersCsvReport = (params) => ({
	method: 'post',
	url: '/admin/download/marketingReport',
	params,
	autoexec: false,
})

export const getUnlockRewardUserService = (params) => ({
	method: 'get',
	url: '/admin/userUnlockedRewards',
	params,
	autoexec: false,
  })

export const updateUserUnlockedRewardService = (data) => ({
	method: 'put',
	url: '/admin/userUnlockedReward',
	data
  })
