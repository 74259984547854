export const getFanTransactionsService = (params) => ({
	method: 'get',
	url: '/fanPurchasesTransaction',
	params,
	autoexec: false
})

export const getCurrenciesService = () => ({
	method: 'get',
	url: '/fanPurchasesTransaction/currency',
	autoexec: false
})

export const getFanTransactionStatusService = () => ({
	method: 'get',
	url: '/fanPurchasesTransaction/statuses',
	autoexec: false
})

export const getFanTransactionsTotalService = () => ({
	method: 'get',
	url: '/fanPurchasesTransaction/total',
	autoexec: false
})

