import { Card, Button } from 'react-bootstrap'
import useRewardUnlock from './useRewardUnlock'
import PageTitle from 'jsx/layouts/PageTitle'
import { LoadMask } from 'components/common/loadMask'

const RewardUnlock = () => {
	const { user, email, amount, loading, handleUnlockRewards, handleEmailChange, setAmount } =
		useRewardUnlock()

	const title = 'Reward unlock'

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<>
					<PageTitle title={title} />
					<Card>
						<Card.Header>
							<div className="row">
								<div className="form-group col-md-6">
									<label>Email Address</label>
									<input
										value={email}
										type="email"
										className="form-control"
										placeholder="User Email"
										onChange={handleEmailChange}
									/>
								</div>
								<div className="form-group col-md-3">
									<label>Unlock Amount</label>
									<input
										value={amount}
										type="number"
										className="form-control"
										placeholder="Amount"
										onChange={(e) => setAmount(Number(e.target.value))}
									/>
								</div>
								<div className="form-group col-md-3">
									<Button className="mt-4" variant="danger" onClick={handleUnlockRewards}>
										Unlock Rewards
									</Button>
								</div>
								<div className="form-group col-md-4">
									<p>Total Rewards: {user?.earningsTotal ?? 0}</p>
								</div>
								<div className="form-group col-md-4">
									<p>Unlocked Rewards: {user?.unlockedRewards ?? 0}</p>
								</div>
								<div className="form-group col-md-4">
									<p>Locked Rewards: {(user?.earningsTotal ?? 0) - (user?.unlockedRewards ?? 0)}</p>
								</div>
							</div>
						</Card.Header>
					</Card>
				</>
			)}
		</>
	)
}

export default RewardUnlock
