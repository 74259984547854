import { Row, Col, Card, Button } from 'react-bootstrap'
import useFanPurchaseTransactionsTable from './useFanPurchaseTransactionsTable'
import { DateTime } from 'luxon'
import PageTitle from 'jsx/layouts/PageTitle'
import styles from './FanPurchaseTransactionsTable.module.scss'
import { CustomSelect } from 'components/common/customSelect'
import { CustomTable } from 'components/common/customTable'
import { SearchField } from 'components/common/searchField'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { formatShortAddress } from 'utils/common.util'


const environment = process.env.REACT_APP_ENVIRONMENT

const FanPurchaseTransactionsTable = () => {
	const {
		loading,
		totals,
		data,
		filters,
		statuses,
		currencies,
		handleClearFilter,
		handleChangeLimit,
		setOffset,
		handleChangeSearch,
		handleChangeStatus,
		handleChangeCurrency,
		handleChangeFromDateRange,
		handleChangeToDateRange,
	} = useFanPurchaseTransactionsTable()


	const polygonscanLinkPrefix = environment === "production" ? "https://polygonscan.com/tx/" : "https://amoy.polygonscan.com/tx/"
	const polygonscanLinkAddressPrefix = environment === "production" ? "https://polygonscan.com/address/" : "https://amoy.polygonscan.com/address/"

	const columns = [
		{
			header: 'Date & Time',
			render: (row) =>
				<div>
					{DateTime.fromJSDate(new Date(row.createdAt), { zone: 'America/Los_Angeles' }).toFormat('yyyy-MM-dd  HH:mm:ss')}
				</div>
		},
		{
			header: 'Name',
			key: 'user.displayName'

		},
		{
			header: 'Email',
			key: 'user.email'
		},
		{
			header: 'Currency',
			key: 'token.name'
		},
		{
			header: 'Amount Spent',
			key: 'purchaseAmount',
		},
		{
			header: '$Fan Sent',
			key: 'awardedFanAmount',
		},
		{
			header: 'Buying Hash',
			render: (row) => row?.paymentBlockchainHash ? (<a href={`${row?.token?.network?.scanUrl}/tx/${row?.paymentBlockchainHash}`} target='blank'>{formatShortAddress(row?.paymentBlockchainHash)}</a>) : ''
		},
		{
			header: 'Distribution Hash',
			render: (row) => row?.transaction?.blockchainHash ? (<a href={`${polygonscanLinkPrefix}${row?.transaction?.blockchainHash}`} target='blank'>{formatShortAddress(row?.transaction?.blockchainHash)}</a>) : ''
		},
		{
			header: 'User Platform Wallet',
			render: (row) => row?.transaction?.destinationWallet?.magicWalletAddress ? (<a href={`${polygonscanLinkAddressPrefix}${row?.transaction?.destinationWallet?.magicWalletAddress}`} target='blank'>{formatShortAddress(row?.transaction?.destinationWallet?.magicWalletAddress)}</a>) : ''
		},
		{
			header: 'Status',
			render: (row) => <div className={row.status.name}>{row.status.name}</div>,
		},
	]
	return (
		<>
			<PageTitle title={'Fan Transactions Management'} />
			<div className="row">
				{totals.map(ele => {
					return (
						<div className="col-xl-2 col-sm-6 m-t35">
							<div className="card card-coin">
								<div className="card-body text-center">
									<h2 className="text-black mb-2 font-w600">{ele.totalPurchaseAmount}</h2>
									<p className="mb-0 fs-14">
										{ele.tokenName}
									</p>
								</div>
							</div>
						</div>
					)
				})}
			</div>
			<Row className={styles.transaction_table}>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title className='col-5 flex-fill mw-100' >Current Transactions</Card.Title>
							<SearchField {...{ search: filters.search, setSearch: handleChangeSearch }} />
							<Button onClick={handleClearFilter} className={styles.refresh_btn}>
								<i className="fa fa-refresh" aria-hidden="true"></i>
							</Button>

						</Card.Header>
						<Card.Header>
							<Row>
								<Col xs={12} md={3} xl={3}>
									<label>Status</label>
									<CustomSelect
										{...{
											value: filters.status,
											setValue: handleChangeStatus,
											options: statuses,
											className: styles.stages,
										}}
									/>
								</Col>
								<Col xs={12} md={3} xl={3}>
									<label>Currency</label>
									<CustomSelect
										{...{
											value: filters.currency,
											setValue: handleChangeCurrency,
											options: currencies,
											className: styles.stages,
										}}
									/>
								</Col>
								<Col xs={12} md={3} xl={3}>
									<label>Start Date</label>
									<DatePicker selected={filters?.from} onChange={(from) => handleChangeFromDateRange({ from })} className="form-control" />
								</Col>
								<Col xs={12} md={3} xl={3}>
									<label>End Date</label>
									<DatePicker selected={filters?.to} onChange={(to) => handleChangeToDateRange({ to })} className="form-control" />
								</Col>
							</Row>
						</Card.Header>
						<Card.Body>
							<CustomTable
								{...{
									data,
									loading,
									limit: filters?.limit,
									setLimit: handleChangeLimit,
									setOffset,
									columns,
									maxLimit: 1000
								}}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default FanPurchaseTransactionsTable
