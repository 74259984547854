import { useMemo, useState } from 'react'
import { showMessage } from 'utils/notification.util'
import _ from 'lodash'
import { getUnlockRewardUserService, updateUserUnlockedRewardService } from 'services/AdminService'
import useAxiosV2 from 'hooks/useAxiosV2'

const useRewardUnlock = () => {
	const [email, setEmail] = useState('')
	const [user, setUser] = useState(null)
	const [amount, setAmount] = useState()
	const [loading, setLoading] = useState(false)
	const { axiosService } = useAxiosV2()

	const clearData = () => {
		setUser(null)
		setAmount(0)
	}

	const getUnlockRewardUser = (email) => {
		if (email === '' || _.isNil(email)) {
			clearData()
			return
		}
		setLoading(true)
		axiosService(getUnlockRewardUserService({ email }), (data, error) => {
			if (!error) {
				setUser(data)
			} else {
				clearData()
			}

			setLoading(false)
		})
	}

	const handleUnlockRewards = () => {
		setLoading(true)
		axiosService(
			updateUserUnlockedRewardService({ userId: user?.userId, amount }),
			(_data, error) => {
				if (!error) {
					showMessage({ message: 'Rewards unlocked successfully' })
					getUnlockRewardUser(email)
				}
				setLoading(false)
			}
		)
	}

	const handleEmailChange = (e) => {
		// react pools events and reuses them for performance reasons
		// meaning the event might not persist in an asynchronous callback like _.debounce
		// so it's better to extract the value from the event and pass it to the debounced function
		const value = e.target.value
		const userEmail = value.trim()
		setEmail(userEmail)
		handleChangeDebounced(userEmail)
	}

	const handleChangeDebounced = useMemo(() => {
		return _.debounce((userEmail) => {
			getUnlockRewardUser(userEmail)
		}, 1000)
	}, [])

	return {
		user,
		email,
		amount,
		loading,
		handleUnlockRewards,
		handleEmailChange,
		setAmount,
	}
}

export default useRewardUnlock
